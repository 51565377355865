import Widget from 'lib/widget';
import {RestClient} from "./../../api/rest_client";
import Url from "../../servos/url";

class BulkStatusUpdate extends Widget {
  bind() {
    bindUpdateInternalStatus.call(this);
  }
}

let bindUpdateInternalStatus = function() {
  this.vehiclesContainer = this.root.find('#vehicles-container');

  this.vehiclesContainer.on('click', ".bulk-internal-status-update", (e) => {

    let vehicleIds = $("input:checkbox.vehicle-list-item:checked").toArray().map(item => item.id);
    let path = "depots/" + $("#depot-vehicles-page").data('depotId') + '/vehicles/bulk_update_internal_status';

    let page = Url.getQueryParamValue('page') || '1';

    swal.fire({
      title: 'Update Internal Status',
      input: 'select',
      inputOptions: gon.bulkActions.statusSelectOptions,
      inputClass: 'form-control'
    }).then((internalStatus) => {
      RestClient.execute({
        method: 'put',
        path: path,
        payload: {
          internal_status: internalStatus,
          vehicle_ids: vehicleIds,
          page: page
        }})
        .success((statusCode, data) => {
          this.renderPartial($("#vehicles-container"), data.partial)

          // Clear the errors
          $('.display-error').html('')

          // Show the errors
          $.each(data.results, (index, vehicle) => {
            $.each(_.get(vehicle, 'errors', []), (key, errors) => {
              let elem = this.vehiclesContainer.find(`.display-status-error[data-field-id='${key}'][data-vehicle-id='${vehicle.vehicle_id}']`)
              if (elem) {
                elem.html("<i class='fa fa-danger text-danger'/>" + errors.join("</br>"))
              }
            })
          })

          // Show Alert of success or failure
          swal.fire({
            type: (data.success ? "success" : "error"),
            text: data.message
          })
        })
        .failure(function(statusCode, statusMessage) {console.log(statusCode, statusMessage);})
    })
  })
};

export default BulkStatusUpdate
