import Widget from "lib/widget"
import AjaxForm from 'servos/ajaxForm';
// import 'jquery-file-download'

class ReportForm extends Widget {
  bind() {
    bindReportTypeSelect.call(this)
  }
}

let bindReportTypeSelect = function() {
  this.root.find('.report-type-select').on('change', (e) => {
    let reportType = e.currentTarget.value;

    $.ajax({
      url: gon.reports.formUrl,
      method: 'get',
      data: { report_type: reportType }
    }).done((response) => {
      this.renderPartial(this.root.find('.report-form-container'), response.html)
      bindExport.call(this)
    })
  })
}

let success = function(response) {
  swal.fire({
    title:  `${I18n.t('views.reports.swal.success.title')}`,
    html:   `${I18n.t('views.reports.swal.success.description')}`,
    type:   "success",
    showCancelButton: false,
    confirmButtonText: `ok`
  })
}

let bindExport = function() {
  var form = this.root.find('.report-form-container').find('form')

  this.reportForm = new AjaxForm(form)
  this.reportForm.on("success", (response) => { success.call(this, response) } )
  this.reportForm.on("error", (e) => { console.log(e) })
}

export default ReportForm;
