import BaseController     from "controllers/accounts/base";
import KaminariPagination from "servos/kaminariPagination"
import FilterForm         from "servos/filterForm";

class Controller extends BaseController {
  call() {
    super.call();

    bindSearchList.call(this);
    bindAssignUser.call(this);
    updateEnabilityOfAssignButton.call(this);
  }
}

let bindAssignUser = function() {
  this.root.find('#assign-to-button').on('click', (e) => {
    e.preventDefault();

    let selectedInspectionIds = $('input:checkbox:checked').get().map(checkbox => checkbox.id);
    let selectedAssigneeId    = $("#assignee-selector").select2('').val();

    swal.fire({
      html: 'Are you sure you wish to reassign the selected inspections?',
      type: "warning",
      showCancelButton: true,
      confirmButtonText: 'Assign!'
    }).then((Confirm) => {
      $.ajax({
        url: "account_users/" + selectedAssigneeId + "/assign",
        method: 'PUT',
        data: {
          selected_inspection_ids: selectedInspectionIds
        },
        dataType: 'json',
        success: (response) => {
          window.location.reload();
        }
      });
    }).catch(() => {
      // We don't need to process any dismissals
    })
  })
}


let bindSearchList = function() {
  this.filterForm = new FilterForm(this.root.find('.filtrable'))

  this.filterForm.on('updated-list', (data) => {
    this.kaminariPagination.trigger('updated-list', data);
    initCheckboxes.call(this);
  })

  this.kaminariPagination = new KaminariPagination(this.root.find('.paginatable'))
  this.kaminariPagination.on("pagination-complete", (data) => {
    initCheckboxes.call(this);
    // here if I need something on pagination complete
  })

  this.on('select2-changed', (e) => {
    this.filterForm.send()
    initCheckboxes.call(this);
  });

  this.on('datepicker-changed', (e) => {
    this.filterForm.send()
    initCheckboxes.call(this);
  });

  this.filterForm.send()
}

let initCheckboxes = function() {
  let self = this;
  $('input:checkbox').change(function() {
    updateEnabilityOfAssignButton.call(this);
  });
  $('#assignee-selector').on('select2:select select2:unselecting', function() {
    updateEnabilityOfAssignButton.call(this);
  });
  $("input:checkbox#select-all").click(function() {
    $("input:checkbox.inspection-list-item").prop("checked", $("input:checkbox#select-all").is(":checked"));
  });
}

let updateEnabilityOfAssignButton = function() {
  let atleastOneInspectionSelected = $('input.inspection-list-item:checkbox:checked').get().length > 0;
  let selectedAssigneeId = $("#assignee-selector").select2().val();
  if (atleastOneInspectionSelected && selectedAssigneeId) {
    $('#assign-to-button').removeClass('disabled')
  } else {
    $('#assign-to-button').addClass('disabled')
  }
}

export default Controller;
