import Widget           from "lib/widget"

class BulkReserveUpdate extends Widget {
  bind() {
    bindReserveFocusChange.call(this);
    bindUpdateReserve.call(this);
  }
}

let bindUpdateReserve = function() {
  this.vehiclesContainer = this.root.find('#vehicles-container');

  this.root.on('click', '.update-reserve', (e) => {
    let data = {}
    _.map(this.root.find('.reserve-input'), (input) => {
      data[input.name] = input.value
    })

    $.ajax({
      url: gon.bulkActions.reserveUrl,
      method: 'PUT',
      data: { bulk_reserve: data },
      dataType: 'JSON'
    }).done((data) => {
      $("#vehicles-container").html(data.partial)

      // Clear the errors
      $('.display-error').html('')

      // Show the errors
      $.each(data.results, (index, vehicle) => {
        $.each(_.get(vehicle, 'errors', []), (key, errors) => {
          let elem = this.vehiclesContainer.find(`.display-reserve-error[data-field-id='${key}'][data-vehicle-id='${vehicle.vehicle_id}']`)
          if (elem) {
            elem.html("<i class='fa fa-danger text-danger'/>" + errors.join("</br>"))
          }
        })
      })

      // Show Alert of success or failure
      swal.fire({
        type: (data.success ? "success" : "error"),
        text: data.message
      })
    }).fail(function(jqXHR, textStatus) {console.log(textStatus, jqXHR);})
  })
}

let bindReserveFocusChange = function() {
  this.root.on('keydown', '.reserve-input', (e) => {
    if ( e.keyCode == 40 ) {
      e.preventDefault();
      let index = parseInt(e.currentTarget.dataset.index);
      focusInput.call(this, index + 1);
    }

    if (e.keyCode == 38) {
      e.preventDefault();
      let index = parseInt(e.currentTarget.dataset.index);
      focusInput.call(this, index - 1);
    }
  })
}

let focusInput = function(index) {
  let Elem = this.root.find(`.reserve-input[data-index=${index}]`)
  if (Elem) { $(Elem).focus(); }
}

export default BulkReserveUpdate;
